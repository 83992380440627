<template>
  <ul class="list list--arrow">
    <li v-for="(li, i) in item" :key="'add-docs-item-list-' + i" :class="'li-' + size">
      <div class="ico ico--arrow me-4" :class="['ico--arrow-' + li.theme, 'ico--arrow-' + size]">
        <img :src="'/img/icons/ui/ico-any-check-' + li.theme + '.svg'" :alt="li.key">
      </div>
      <p class="text fs-14 fw-400 lh-17" :class="['tc-' + tColor, 'fs-sm-' + fs[size]]">{{ li.text }}</p>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Array
    },
    size: {
      required: false,
      default: 'md'
    },
    tColor: {
      required: false,
      default: 'night'
    }
  },
  data: () => ({
    fs: {
      xs: '12',
      sm: '14',
      md: '16',
      lg: '18',
      xl: '20'
    }
  })
}
</script>
