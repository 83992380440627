<template>
  <div class="page page--careers">
    <section class="section" id="sec-1" data-sec="1">
      <comp-sample :title="cc.sec1.title" :text="cc.sec1.text" :btn="cc.sec1.btn" :img="'/img/pages/careers/sec1/img-1.png'" :imgmob="'/img/pages/careers/sec1/mob/img-1.png'" :isAbstrset="false" :theme="'blue'"/>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 d-none d-lg-block">
            <div class="img img--careers-sec2">
              <img src="/img/pages/careers/sec2/img-1.png" alt="About">
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
            <div class="wrapper d-flex flex-column justify-content-center">
              <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec2.title }}</h2>
              <div class="img img--careers-sec2 d-lg-none mb-4">
                <img src="/img/pages/careers/sec2/mob/img-1.png" alt="About">
              </div>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-4 mb-md-5">{{ cc.sec2.text }}</p>
              <div class="d-flex justify-content-center justify-content-md-start">
                <a :href="cc.sec2.link.to" v-smooth-scroll="{ duration: 1000, offset: -100 }" class="link link--arrow link--td-none">
                  <span class="link fs-16 fs-md-18 fs-xl-20 tc-blue">
                    {{ cc.sec2.link.text }}
                  </span>
                  <i class="ico ico--ui-arrow">
                    <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow">
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-3" data-sec="3" style="display: none !important">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--inter-footprint">
              <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-md-center">{{ cc.sec3.title[0] }}</h2>
              <h2 class="title fs-22 fs-sm-40 fs-md-32 fs-xl-42 fw-400 lh-13 ta-md-center mb-3 mb-md-4">{{ cc.sec3.title[1] }}</h2>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-md-center mb-4 mb-md-5">{{ cc.sec3.text[0] }}</p>
              <p class="text tc-blue fs-14 fs-sm-16 fw-400 lh-20 ta-md-center">{{ cc.sec3.text[1] }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-4" data-sec="4">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
            <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center">{{ cc.sec4.title }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <div class="block block--benefits-careers">
              <comp-list-arrow :item="cc.sec4.list[0]" size="lg"/>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="block block--benefits-careers">
              <comp-list-arrow :item="cc.sec4.list[1]" size="lg" class="mb-3"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-5" data-sec="5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--achievements">
              <div class="achievements">
                <div v-for="(item, i) in cc.sec5.achievements" :key="'ach-' + i">
                  <h3 class="title tc-white fs-58 fs-xl-58 fw-600 lh-13 ta-center">{{ item[0] }}</h3>
                  <p class="text tc-white ta-center">{{ item[1] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--gray" id="sec-6" data-sec="6">
      <div class="container">
        <div class="row mb-5 pb-xl-5">
          <div class="col-12">
            <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center mb-3 mb-md-4">{{ cc.sec6.title[0] }}</h2>
            <p class="text tc-blue fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center">{{ cc.sec6.text }}</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="vacancy">
              <div class="vacancy__head mb-3 mb-md-4">
                <div class="row">
                  <div class="col-12 col-lg-5 d-none d-lg-block">
                    <p>{{ cc.sec6.title[1] }}</p>
                  </div>
                  <div class="col-12 col-lg-4 d-none d-lg-block">
                    <p>{{ cc.sec6.title[2] }}</p>
                  </div>
                  <div class="col-12 col-lg-3 d-none d-lg-block">
                    <p>{{ cc.sec6.title[3] }}</p>
                  </div>
                </div>
              </div>
              <router-link :to="`/${$i18n.locale}` + '/careers/vacancy'"
                           tag="div" class="vacancy__item"
                           v-for="(item, i) in cc.sec6.vacancies" :key="'vac' + i">
                <div class="vacancy__item-body" @click="reassignVacancy(item.key)">
                  <div class="row">
                    <div class="col-12 col-lg-5 mb-3 mb-lg-0">
                      <div class="wrapper d-flex align-items-center">
                        <p class="fs-16 fs-sm-18">{{ item.name }}</p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                      <div class="wrapper d-flex align-items-center">
                        <p class="tc-silver fs-16">{{ item.type }}</p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3">
                      <div class="wrapper d-flex align-items-center justify-content-between">
                        <p class="tc-blue fs-16">{{ item.location }}</p>
                        <div class="vacancy__btn">
                          <i class="ico ico--ui-arrow">
                            <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow">
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <router-link tag="a" :to="`/${$i18n.locale}` + '/careers/vacancy'" class="link--td-none">
              <div @click="reassignVacancy('none')" class="btn btn-3-blue btn-w-240 btn-w-lg-fc btn-h-66 btn-h-lg-52 mx-auto px-3 px-lg-5">
                <span class="ta-center">{{ cc.sec6.btn.text[0] }}<br class="d-lg-none"/> {{ cc.sec6.btn.text[1] }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompSample from '@/components/Sample'
import CompListArrow from '@/components/lists/ListArrow'
export default {
  data: () => ({}),
  components: {
    CompSample, CompListArrow
  },
  computed: {
    cc () {
      return this.$t('pages.careers')
    }
  },
  methods: {
    reassignVacancy: function (key) {
      console.log(key)
      this.$store.state.vacancy = key
    }
  },
  metaInfo () {
    const meta = this.$t('meta.careers')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
